// src/App.js
import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Model from './Model';
import styled from 'styled-components';

// Estilo para o efeito Matrix no fundo
const MatrixBackground = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0; // Define o fundo abaixo de todos os outros elementos
`;

// Contêiner para o ASCII art centralizado acima do Canvas
const ASCIIContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF; // Verde neon para o ASCII
  font-family: monospace;
  font-size: 16px;
  white-space: pre;
  z-index: 2;
`;

// Estilo para o container centralizado com borda
const CenterContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  border: 2px solid #00ff00; // Borda verde
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8); // Fundo semi-transparente
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%); // Centraliza o container
  z-index: 2;
  color: white; // Cor do texto em branco
`;

// Estilo para o botão de copiar
const CopyButton = styled.button`
  padding: 8px 12px;
  background-color: #00ff00;
  color: #000;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #00cc00;
  }
`;

// Estilo para os ícones X e Telegram, centralizados abaixo do container
const IconsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
  z-index: 2;
`;

// Estilo para cada botão de ícone
const IconButton = styled.button`
  padding: 8px 16px;
  background-color: #00ff00;
  color: #000;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #00cc00;
    transform: scale(1.05);
  }
`;

function App() {
  const matrixRef = useRef(null);

  // Função para o efeito Matrix
  useEffect(() => {
    const canvas = matrixRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const letters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const fontSize = 14;
    const columns = canvas.width / fontSize;
    const drops = Array(Math.floor(columns)).fill(1);

    function drawMatrix() {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#00ff00';
      ctx.font = `${fontSize}px monospace`;

      drops.forEach((y, index) => {
        const text = letters[Math.floor(Math.random() * letters.length)];
        const x = index * fontSize;
        ctx.fillText(text, x, y * fontSize);

        if (y * fontSize > canvas.height && Math.random() > 0.975) {
          drops[index] = 0;
        }
        drops[index]++;
      });
    }

    const interval = setInterval(drawMatrix, 50);
    return () => clearInterval(interval);
  }, []);

  // Função para copiar o texto
  const copyToClipboard = () => {
    navigator.clipboard.writeText("nn3FgZcYecSUzTxNh39d2gzU1qdHzVFNwiKmdVfpump");
    alert("Copiado!");
  };

  return (
    <>
      {/* Canvas para o efeito Matrix */}
      <MatrixBackground ref={matrixRef} />

      {/* ASCII Art centralizado acima do modelo 3D */}
      <ASCIIContainer>
        {` ___  ________  _____ ______      
|\\  \\|\\   __  \\|\\   _ \\  _   \\    
\\ \\  \\ \\  \\|\\ /\\ \\  \\\\\\__\\ \\  \\   
 \\ \\  \\ \\   __  \\ \\  \\\\|__| \\  \\  
  \\ \\  \\ \\  \\|\\  \\ \\  \\    \\ \\  \\ 
   \\ \\__\\ \\_______\\ \\__\\    \\ \\__\\
    \\|__|\\|_______|\\|__|     \\|__|`}
      </ASCIIContainer>

      {/* Canvas ocupando a tela inteira para o modelo 3D */}
      <Canvas style={{ width: '100vw', height: '100vh', position: 'relative', zIndex: 1 }}>
        <OrbitControls />
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <Model url="/modelo.gltf" scale={[1, 1, 1]} />
      </Canvas>

      {/* Container centralizado com borda, texto e botão de cópia */}
      <CenterContainer>
        <span>nn3FgZcYecSUzTxNh39d2gzU1qdHzVFNwiKmdVfpump</span>
        <CopyButton onClick={copyToClipboard}>COPY</CopyButton>
      </CenterContainer>

      {/* Ícones do X e Telegram */}
      <IconsContainer>
        <IconButton onClick={() => window.open("https://x.com/1stIBMcomputer", "_blank")}>X</IconButton>
        <IconButton onClick={() => window.open("https://t.me/firstibm", "_blank")}>Telegram</IconButton>
      </IconsContainer>
    </>
  );
}

export default App;
