// src/Model.js
import React from 'react';
import { useGLTF } from '@react-three/drei';

function Model({ url, scale = [1, 1, 1] }) {
  const { scene } = useGLTF(url);
  return <primitive object={scene} scale={scale} />;
}

export default Model;
